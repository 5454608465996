import { Injectable } from '@angular/core';
import { CoreLib_Services_Http_Base_BaseService } from 'core';
import 'rxjs/operator/catch';



@Injectable({
  providedIn: 'root',
})
export class HttpCommonService {
  constructor(private baseService: CoreLib_Services_Http_Base_BaseService) { }

  private areaName = 'common/';

  // public async license(): Promise<LicenseDeskResponse> {
  //   return this.baseService.get<LicenseDeskResponse>(this.baseService.baseUrl + this.areaName + 'license').toPromise();
  // }

}
