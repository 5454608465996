import { Injectable } from '@angular/core';
import { CommonErrorReportRequest } from '@mydto/CommonErrorReportRequest';
import { CoreLib_Services_Common_ApplicationStateService, CoreLib_Services_Common_AuthenticationService, CoreLib_Services_Common_MessageService, CoreLib_Services_Common_WebViewHostService, CoreLib_Services_Http_Base_BaseService } from 'core';

@Injectable({ providedIn: 'root' })
export class MessageService extends CoreLib_Services_Common_MessageService {


  constructor(applicationStateService: CoreLib_Services_Common_ApplicationStateService, private authenticationService: CoreLib_Services_Common_AuthenticationService, private baseService: CoreLib_Services_Http_Base_BaseService, webViewHostService: CoreLib_Services_Common_WebViewHostService) {
    super(applicationStateService, webViewHostService);
  }

  // Estendo il metodo setError per inviare l'errore client a FirstAid
  override setError(message: string, statusCode: number) {
    super.setError(message, statusCode);
    if (statusCode != 500) {
      this.sendErrorToFirstAid(message, statusCode);
    }
  }

  public sendErrorToFirstAid(message: string, statusCode: number) {
    try {

      // const request = new CommonErrorReportRequest();
      // request.application = 'Smart.1 Desk Client';
      // request.operator = this.authenticationService.getAccountName();
      // const company = this.applicationStateService.getTypedSetting<CommonCompanyDataResponse>('Company', null);

      // if (company != null) {
      //   request.customer = company.name;
      // } else {
      //   request.customer = 'Not Setted';
      // }

      // request.eventId = statusCode;
      // request.level = 'Error';
      // request.data = message;

      // const response = this.baseService.http.post('http://firstaid.ribo.it/api/errorReport', request).toPromise();
    } catch {

    }
  }
}
