<div class="page-login">
  <div class="rounded box">

    <div class="row">
      <div class="col-md-12">
        <h1>{{localizeByCommon['CHANGE_PASSWORD_TITLE']}}</h1>

        <app-text-box [header]="localizeByCommon['USERNAME']" [(text)]="name" [maxLength]="25"
          [disabled]="true"></app-text-box>

        <app-text-box [header]="localizeByCommon['PASSWORDOLD']" [(text)]="passwordOld" [maxLength]="20"
          [type]="'password'" #passwordold_ref (inputKeyUp)="onKeyUp($event)"></app-text-box>

        <app-text-box [header]="localizeByCommon['PASSWORDNEW']" [(text)]="passwordNew" [maxLength]="20"
          [type]="'password'" #passwordnew_ref (inputKeyUp)="onKeyUp($event)"></app-text-box>

        <app-text-box [header]="localizeByCommon['PASSWORDNEWCONFIRM']" [(text)]="passwordNewConfirm" [maxLength]="20"
          [type]="'password'" #passwordnewconfirm_ref (inputKeyUp)="onKeyUp($event)"></app-text-box>

        <span class="error-span" *ngIf="changePasswordFailed" [innerText]="errorText"></span>

        <div class="row">
          <div class="col-md-6 text-center mt-2">
            <app-button [type]="'submit'" (click)="change()" text="{{localizeByCommon['BTN_CONFIRM']}}"
              [buttonClass]="'k-primary'"></app-button>
          </div>
          <div class="col-md-6 text-center mt-2">
            <app-button (click)="goBack()" text="{{localizeByCommon['BTN_BACK']}}"
              [buttonClass]="'k-secondary'"></app-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>