import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { CoreLib_Services_Common_LicenseService, CoreLib_Services_Http_Base_BaseService } from 'core';
import { HttpCommonService } from '../http/_common/http.common.service';


@Injectable({ providedIn: 'root' })
export class LicenseService extends CoreLib_Services_Common_LicenseService {


  constructor(private injector: Injector, private httpCommonService: HttpCommonService) {
    super();
  }

  canActivateHandler(): boolean {
    // const router = this.injector.get(Router) as Router;

    // // go license error page

    // if (this.licenseResponseIsNull) {
    //   return false;
    // } else {
    //   if (!this.isValidLicense) {
    //     router.navigate(['/license-error']);
    //   }

    //   return this.isValidLicense;
    // }

    return true;
  }

  async init(): Promise<void> {

    //this.isValidLicense = true;

    // const licenseResponse = await this.httpCommonService.license();
    // if (licenseResponse != null && licenseResponse.modules != null && licenseResponse.modules.indexOf('DESK') > -1) {
    //   this.modules = licenseResponse.modules;
    //   this.isValidLicense = true;
    // } else {
    //   if (licenseResponse == null) {
    //     this.licenseResponseIsNull = true;
    //   }
    //   this.isValidLicense = false;
    // }
  }

  isActiveModule(module: string): boolean {
    //return this.modules.indexOf(module) > -1;

    return true;
  }



}
