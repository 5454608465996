import { Component, Injector } from '@angular/core';
import { ControlsLib_Components_Base_BaseAppComponent, ControlsLib_Services_Common_ControlsTranslationsService, } from 'controls';
import { CoreLib_Services_Common_AuthenticationService, CoreLib_Services_Common_LicenseService, CoreLib_Services_Common_ToastService, CoreLib_Services_Common_TranslationService, CoreLib_Services_Common_WebViewHostService, CoreLib_Services_Http_DeskCommonService } from 'core';
import { CommonMenuGroupDataResponse, CommonMenuItemDataResponse } from 'dto';
import { ApplicationInitService } from './services/core-extension/application-init.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends ControlsLib_Components_Base_BaseAppComponent {

  public emailSupport: string = '';

  public title: string = '';
  
  public cookiePolicyViewed: boolean = false;

  constructor(
    injector: Injector,
    public override authenticationService: CoreLib_Services_Common_AuthenticationService,
    licenseService: CoreLib_Services_Common_LicenseService,
    deskApiCommonService: CoreLib_Services_Http_DeskCommonService,
    toastService: CoreLib_Services_Common_ToastService,
    webViewHostService: CoreLib_Services_Common_WebViewHostService,
    public translateService: CoreLib_Services_Common_TranslationService,
    public controlsTranslationsService: ControlsLib_Services_Common_ControlsTranslationsService,
    public applicationInitService: ApplicationInitService
  ) {
    super(injector, authenticationService, licenseService, deskApiCommonService, toastService, webViewHostService);

    this.authenticationService.isWindowsAuth = false;
    this.authenticationService.unsecuredUrls.push("/signature");
    this.authenticationService.unsecuredUrls.push("/webcam");
  }

  override async ngOnInit(): Promise<void> {
    await super.ngOnInit();

    this.title = this.translateService.localizeByCommon['FRONTEND_TITLE'];
    this.titleService.setTitle(this.title);

    var emailSupport = this.applicationStateService.getStringSetting('EmailSupport', 'accrediti@atalanta.it');
    this.emailSupport = emailSupport;
  }

  public goToHome() {
    this.router.navigate(['']);
  }

  public goToRegistration() {
    this.router.navigate(['registration/view']);
  }

  public logout() {
    this.authenticationService.logout();
    this.router.navigate(['']);
  }

  public override setTitle(group: CommonMenuGroupDataResponse, item: CommonMenuItemDataResponse): void {
    this.titleService.setTitle(this.title);
  }

  public override getSignalRBaseUrl(): string {
    return "http://localhost:55513";
  }
  
}
