import { AfterViewInit, Component, Injector, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ControlsLib_Components_Base_BaseViewComponent, ControlsLib_Components_TextBox_TextBoxComponent } from 'controls';
import { CoreLib_Classes_StringHelper, CoreLib_Enums_PopupButtonsTypes, CoreLib_Enums_PopupResultTypes, CoreLib_Enums_PopupTitlesTypes, CoreLib_Services_Common_AuthenticationService, CoreLib_Services_Common_TranslationService, CoreLib_Services_Http_DeskCommonService } from 'core';
import { CommonLoginRequest, CommonLoginResponse } from 'dto';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-login-pers',
  templateUrl: './login.component.html',
})
export class LoginPersComponent extends ControlsLib_Components_Base_BaseViewComponent implements OnInit, OnDestroy, AfterViewInit {

  private authenticationService_reloginRequested_subscribe: Subscription;

  @ViewChild('name_ref', { static: true }) nameField: ControlsLib_Components_TextBox_TextBoxComponent;
  @ViewChild('password_ref', { static: true }) passwordField: ControlsLib_Components_TextBox_TextBoxComponent;

  @Input() public name: string = '';
  @Input() public isReloginMode: boolean = false;

  public password: string = '';

  public errorText: string;
  public loginFailed: boolean = false;

  public loginFailedCount: number = 0;
  public loginFailedText: string = '';
  public mailSupport: string = '';

  public get imageHeaderUrl(): string {
    return "assets/images/header_" + this.translateService.cultureName + ".png";
  }

  constructor(injector: Injector,
              private deskApiCommonService: CoreLib_Services_Http_DeskCommonService,
              private translateService: CoreLib_Services_Common_TranslationService,
              private authenticationService: CoreLib_Services_Common_AuthenticationService) {
    super(injector);
    this.formReference = 'CommonLogin';

    this.authenticationService_reloginRequested_subscribe = this.authenticationService.reloginRequested.subscribe((value: boolean) => {
      this.setLoginTheme();
      this.setFocusOnName();
    });
  }

  override async ngOnInit() {
    await super.ngOnInit();

    var mailSupport = this.applicationStateService.getStringSetting("EmailSupport", "accrediti@atalanta.it");
    this.loginFailedText = this.localizeByCommon['LOGINFAILED_ATTEMPTSEXCEEDED_TEXT'].replace("@@MAILTO@@", mailSupport).replace("@@MAILTO@@", mailSupport);

    if (this.authenticationService.isLoggedIn()) {
      this.authenticationService.logout();

    }

    // Se si atterra sulla pagina di login, svuoto il token perchè significa che l'utente VUOLE riloggarsi
    if (!this.isReloginMode) {
      this.authenticationService.isInLogin = true;
      this.authenticationService.logout();
    }

    this.setLoginTheme();
  }

  private setLoginTheme() {
    // La login ha sempre il tema standard
    this.navigationService.changeTheme('standard', false);
  }

  private setBeforeReloginTheme() {
    // Reimposto il tema attivo prima della relogin
    if (this.navigationService.getTheme() != '') {
      this.navigationService.changeTheme(this.navigationService.getTheme(), false);
    }
  }
  override async ngAfterViewInit(): Promise<void> {
    await super.ngAfterViewInit();

    if (!this.isReloginMode) {
      this.setFocusOnName();
    }
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this.authenticationService_reloginRequested_subscribe != null) {
      this.authenticationService_reloginRequested_subscribe.unsubscribe();
    }

    this.authenticationService.isInLogin = false;
  }

  async onKeyUp(event: KeyboardEvent) {
    event.preventDefault();
    if (event.keyCode == 13) {
      await this.login();
    }
  }

  private setFocusOnName() {
    if (this.nameField != null) {
      setTimeout(() => this.nameField.focus(), 500);
    }
  }
  private setSelectOnName() {
    if (this.nameField != null) {
      setTimeout(() => this.nameField.select(), 500);
    }
  }

  private setFocusOnPassword() {
    if (this.passwordField != null) {
      setTimeout(() => this.passwordField.focus(), 500);
    }
  }

  // Effettuo la chiamata alla Web Api per il login manuale
  async login() {

    this.loginFailed = false;
    this.errorText = '';

    if (this.name == null || this.name.length == 0) {
      this.loginFailed = true;
      this.errorText = this.localizeByCommon['NAMEREQUIRED'];
      this.setFocusOnName();
      return;
    }

    if (this.password == null || this.password.length == 0) {
      this.loginFailed = true;
      this.errorText = this.localizeByCommon['PASSWORDREQUIRED'];
      this.setFocusOnPassword();
      return;
    }

    // costruisco l'oggetto request
    const loginRequest = new CommonLoginRequest();
    loginRequest.name = this.name;
    loginRequest.password = this.password;

    // invoco la Web Api
    const result = await this.deskApiCommonService.login(loginRequest);
    await this.processLoginResult(result);
  }

  // Processo la risposta della chiamata alla Web Api per il login manuale
  async processLoginResult(data: CommonLoginResponse): Promise<void> {
    if (data != null && data.isValid) {
      // Il login è avvenuto con successo: procedo con la navigazione
      this.loginFailedCount = 0;
      this.loginFailed = false;
      await this.authenticate(data);
      // Vado a fullscreen se è stato impostato di andare in fullscreen in automatico al login
      if (this.navigationService.getIsDefaultFullScreenLogin()) {
        this.navigationService.goToFullScreen();
      }
    } else {
      // se fallisce anche il login manuale mostro un avviso di errore
      this.loginFailed = true;

      if (data.reasonIsNotValid == "PASSWORDEXPIRED") {
        this.authenticationService.setAccountName(data.accountName);
        this.authenticationService.setAccountDescription(data.accountDescription);

        if (await this.popupService.showMessage(CoreLib_Enums_PopupTitlesTypes.Warning, this.localizeByCommon['PASSWORDEXPIRED'], CoreLib_Enums_PopupButtonsTypes.Ok) == CoreLib_Enums_PopupResultTypes.Ok) {
          this.router.navigate(['/change-password']);
        }
      } else {
        this.loginFailedCount++;
        this.errorText = this.localizeByCommon[data.reasonIsNotValid];
      }

      this.password = '';
      this.setSelectOnName();
    }
  }

  // Effettua il redirect alla pagina richiesta prima del login. Se l'utente non aveva richiesto una pagina in particolare, redirigo a homepage
  private async authenticate(data: CommonLoginResponse) {
    // Memorizzo i dati di login...
    this.authenticationService.setAccountName(data.accountName);
    this.authenticationService.setAccountDescription(data.accountDescription);
    this.authenticationService.setLoginUid(data.loginUid);
    this.authenticationService.isInLogin = false;
    this.authenticationService.attemptRelogin = false;
    this.authenticationService.loginRequested.next(true);
    // this.authenticationService.reloginRequested.next(false);

    if (!this.isReloginMode) {

      // se non mi arriva un tema dalle impostazioni account
      if (CoreLib_Classes_StringHelper.isNullOrWhiteSpace(data.deskTheme)) {
        data.deskTheme = 'standard';
      }

      // dopo la login viene ripreso il tema originale impostato sull'utente
      this.navigationService.setTheme(data.deskTheme);

      // Navigo alla pagina richiesta solo se sono in modalità login
      if (this.authenticationService.redirectUrl == '' || this.authenticationService.redirectUrl == '/' || this.authenticationService.redirectUrl == '/login') {
        this.router.navigate([this.applicationStateService.getStringSetting('DeskDefaultStartPage', '/home')]);
      } else {
        this.router.navigateByUrl(this.authenticationService.redirectUrl);
        this.authenticationService.redirectUrl = '';
      }
    } else {
      // dopo una relogin (per sessione scaduta) reimposto il tema che era in uso
      this.setBeforeReloginTheme();
    }

  }
  
  public goToHome() {
    this.router.navigate(['']);
  }

}
